export default {
  locale: 'fr',
  messages: {
    navbar_databases: 'Databases',

    navbar_relations: 'Relations',
    navbar_policies: 'Polices',
    navbar_campaigns: 'Campagnes',
    navbar_search: 'Recherche',
    navbar_insurers: 'Assureurs',
    navbar_branches: 'Branches',
    navbar_emails_templates: "Modèles d'email",
    // navbar_policies: 'Polices',
    // navbar_offers: 'Offres',
    // navbar_reminders: 'Rappels',
    // navbar_visits: 'Visites',
    // navbar_claims: 'Sinistres',
    navbar_settings: 'Paramètres',
    navbar_settings_email: 'Paramètres e-mail',

    navbar_account: 'Compte',
    navbar_subscription: 'Abonnement',
    navbar_password_change: 'Changer de mot de passe',
    navbar_mfa_setup: 'Authentification à deux facteurs',
    navbar_signin: 'Connection',
    navbar_signout: 'Déconnection',

    route_home: 'Accueil',
    route_broker: 'Broker',
    route_relations: 'Liste des Relations',
    route_relations_add: 'Ajouter une Relation',
    route_relation: 'Relation',
    route_relation_policies: 'Polices',
    route_relation_offer_add_vehicule: "Nouvel appel d'offre VHM",
    route_relation_offer_vehicule: "Appel d'offre VHM {reference}",
    route_policies: "Polices",
    route_settings: "Paramètres",
    route_settings_email: "Paramètres e-mail",
    route_emails_templates: "Modèles d'emails",
    route_emails_template: "Modèle d'emails",
    route_documents: 'Documents',
    route_search_relations: 'Recherche parmis les relations',
    route_search_policies: 'Recherche parmis les polices',
    route_search_index: 'Indexer pour la recherche',

    session_change: 'Base de donnée connectée',

    relation_address_title: 'Adresse de {name}',
    relation_address_subtitle:
      'Recherchez parmis les adresses référencées pour remplir tous les champs',
    relation_address_envelope_c4: 'C4',
    relation_address_envelope_c5: 'C5',
    relation_address_envelope_c56: 'C5/6',
    auth_title: 'Authentification',
    auth_subtitle:
      "Page technique présentant les différents jetons d'authentification",

    signin_title: 'Connectez-vous à BRKR',
    signin_subtitle:
      "Si vous avez login problème s'il vous plaît contacter le support",
    signin_google: 'Se connecter avec Google',
    signin_or: 'ou',
    signin_email: 'email',
    signin_username: "nom d'utilisateur",
    signin_password: 'Mot de passe',
    signin_login: 'Connection',
    signin_create: 'Créer un compte',
    signin_lost: 'Mot de passe perdu ?',
    password_lost_title: 'Changer de mot de passe',
    password_lost_email: 'email',
    password_lost_confirm: 'continuer',
    signin_challenge_password_new: 'Veuillez changer de mot de passe',
    signin_password_new: 'nouveau mot de passe',
    signin_password_confirm: 'confirmation du mot de passe',
    signin_challenge_password_confirm: 'Changer mon mot de passe',

    sign_out_title: 'Déconnection',
    sign_out_subtitle: 'Vous êtes maintenant correctement déconnecté de BRKR',
    sign_out_success: 'Déconnection réussie',
    sign_out_sign_in: 'Se reconnecter',

    password_lost_change_title: 'Changer de mot de passe',
    password_lost_change_subtitle:
      'Vous avez reçu un code par email ou par SMS que vous pouvez utiliser pour associer un nouveau mot de passe à votre compte',
    password_lost_change_username: "nom d'utilisateur",
    password_change_code: 'code',
    password_lost_change_password: 'nouveau mot de passe',
    password_lost_change_password_confirm:
      'confirmation du nouveau mot de passe',
    password_lost_change_confirm: 'Réintilisaliser le mot de passe',

    password_change_title: 'Changer le mot de passe',
    password_change_subtitle: '',
    password_change_password_old: 'Mot de passe actuel',
    password_change_password_new: 'nouveau mot de passe',
    password_change_password_confirm: 'confirmation du nouveau mot de passe',

    password_change_email: 'email',
    password_change_email_help:
      'Vous recevrez un e-mail avec un lien pour changer votre mot de passe',
    password_change_confirm: 'Changer le mot de passe',
    password_reset_title: 'Nouveau mot de passe',
    password_reset_subtitle:
      'Veuillez saisir et confirmer votre nouveau mot de passe',
    password_reset_password: 'Mot de passe',
    password_reset_password_confirm: 'Confirmez le mot de passe',
    password_reset_confirm: 'Définir un nouveau mot de passe',
    register_title: 'Créer un compte',
    register_subtitle:
      'Veuillez saisir votre adresse email et choisissez un mot de passe',
    register_email: 'email',
    register_password: 'mot de passe',
    register_firstname: 'prénom',
    register_lastname: 'nom de famille',
    register_already: 'Vous avez déjà un compte ?',
    register_confirm: 'Créer mon compte',
    signup_confirm_title: 'Vérification',
    signup_confirm_subtitle:
      'Vous avez reçu un code par email ou SMS pour vérifier vos coordonnées.',
    signup_confirm_username: "nom d'utilisateur",
    signup_confirm_resend: 'renvoyer un code',
    signup_confirm_code: 'code',
    signup_confirm_confirm: 'Continuer',
    signout_title: 'Déconnexion de BRKR',
    signout_subtitle:
      'Lorsque vous déconnectez toutes les informations stockées sur votre appareil est nettoyé',
    signout_success: 'Vous avez bien déconnecté',
    signout_signin: 'Se reconnecter à BRKR',

    auth_error_password_reset_required_exception:
      'Vous devez ré-initialiser votre mot de passe en utilisant la fonction mot de passe perdu.',

    update_major_title: 'Chargement en cours…',
    update_major_records: 'enregistrements restant',

    error_report_title: 'Une erreur est survenue',
    error_title: "Oups, une erreur s'est produite",

    databases_title: 'Bases de données',
    databases_subtitle:
      'Les bases de données contiennent des données avec lesquelles vous travailler',
    database: 'Base de données',
    database_open: 'Ouvrir',
    database_settings: 'Paramètres',
    database_backup: 'Sauvegarder',

    user_roles_manager: 'Manager',
    user_accesses_rw: 'Lecture & Ecriture',

    home_bc: 'BRKR',
    home_title: 'Accueil',
    home_broker: 'Ouvrir le compte broker',


    broker_customers: 'Clientèle',
    broker_relations: 'Relations',
    broker_relations_help: 'Liste des clients, prospects, particuliers et les entreprises',
    broker_birthdays: 'Prochains anniversaires',
    broker_birthdays_help: 'Les anniversaires pour les trois prochains jours',
    broker_policies: 'Polices',
    broker_policies_help: "Liste de toutes les polices d'assurances gérer par le broker",
    broker_products: 'Prestations',
    broker_insurers: 'Assureurs',
    broker_insurers_help: 'Liste et configuration des assureurs',
    broker_branches: 'Branches',
    broker_branches_help: "Liste et configuration des branches d'assurances",
    broker_operators: 'Opérateurs',
    broker_users: 'Utilisateurs',
    broker_users_help: "Gestion des utilisateurs, de leur rôles et de leurs droits d'accès",
    broker_settings: "Paramètres",
    broker_emails: "Paramètre des emails",
    broker_emails_help: "Paramètre d'expédition des emails",
    broker_emails_templates: "Modèles d'emails",
    broker_emails_templates_help: "Modifier les modèles des emails",

    birthdays_title: 'Prochains anniversaires',

    relations_title: 'Relations',
    relations_subtitle: 'Prospect, clients, archives',
    relations_add: 'Ajouter une nouvelle relation',
    relations_search: 'Chercher une relation',
    relations_include_archived: 'montrer les relations archivés',

    relations_add_title: 'Ajouter une relation',
    relations_add_subtitle: '',
    relations_add_create: 'Ajouter',

    relation_updated: 'MàJ',
    relation_name: 'Nom',
    relation_status: 'Statut',
    relation_status_prospect: 'Prospect',
    relation_status_customer: 'Client',
    relation_tags: 'Étiquette',
    relation_tags_new: 'étiquette',
    relation_assistant: 'Chargé',
    relation_manager: 'Gestionnaire',
    relation_intermediary: "Apporteur d'affaire",

    relation_title: '{name} ({status})',
    relation_subtitle: 'né le {birthDate}',

    relation_tab_general: 'Général',
    relation_tab_prospection: 'Prospection',
    relation_tab_offers: 'Offres',
    relation_tab_policies: 'Polices',
    relation_tab_sinisters: 'Sinistres',
    relation_tab_documents: 'Documents',
    relation_tab_emails: 'Emails',

    relation_company: 'Société',
    relation_civility: 'Civilité',
    relation_given_name: 'Prénom',
    relation_family_name: 'Nom',
    relation_birth_date: 'Naissance',
    relation_nationality: 'Nationalité',
    relation_residence_permit: 'Permis de Séjour',
    relation_email: 'email',

    relation_phone_business: 'Tél. Pro.',
    relation_phone_private: 'Tél. Privé',
    relation_phone_mobile: 'Tél. Mobile',

    relation_management_edit: 'Modifier la gestion',

    relation_name_edit: 'Modifier',
    relation_name_title: '{name} (relation)',
    relation_name_subtitle: 'Mettre à jour le nom de la relation',

    relation_address: 'Adresse',
    relation_address_search: "Cherchez l'adresse",
    relation_address_street: 'Rue',
    relation_address_street_number: 'Numéro',
    relation_address_post_office_box_number: 'Case Postale',
    relation_address_postal_code: 'NPA',
    relation_address_locality: 'Localité',
    relation_address_region: 'Region',
    relation_address_country: 'Pays',
    relation_address_map: 'carte',
    relation_address_edit: "Modifier l'adresse",

    relation_coordinates_edit: 'Modifier les coordonnées',
    relation_coordinates_telephone: 'Numéro de téléphone privé',
    relation_coordinates_telephone_abbrev: 'Tél. Privé',
    relation_coordinates_telephone_work: 'Numéro de téléphone professionnel',
    relation_coordinates_telephone_work_abbrev: 'Tél. Pro.',
    relation_coordinates_telephone_mobile: 'Numéro de téléphone mobile',
    relation_coordinates_telephone_mobile_abbrev: 'Mobile',
    relation_coordinates_telephone_mobile_sms: 'sms',
    relation_coordinates_fax_number: 'Numéro de FAX',
    relation_coordinates_email: 'email',

    relation_notes_edit: 'Modifier les notes',
    relation_notes_subtitle: 'Modifier les notes',

    relation_bank_account: 'Coordonnées de Paiement',
    relation_bank_account_company: 'Société Bancaire',
    relation_bank_account_iban: 'IBAN',
    relation_bank_account_note: 'Notes',
    relation_bank_account_edit: 'Modifier les coordonnées bancaires',
    relation_bank_account_subtitle: 'Mettre à jour les coordonnées de paiement',

    relation_notes: 'Notes',

    relation_driver: 'Conducteur',
    relation_driver_edit: 'Modifier',
    relation_driver_subtitle: 'Mettre à jour les informations pour la conduite',
    relation_driver_driving_license_date:
      "Date d'obtention du permis de conduire",

    relation_general_actions: 'Actions',
    relation_archive: 'Archiver',
    relation_unarchive: 'Désarchiver',

    relation_prospect_actions_title: 'Liste des vérifications',

    relation_policies_title: "Polices d'assurance",
    relation_documents_title: 'Documents de la relation',
    relation_policy_documents_title: "Documents de la police d'assurance",

    relation_offers_add: "Faire un appel d'offres",
    relation_offers_add_vehicule: "Appel d'offre VHM",

    offer_id: 'Identification',
    offer_reference: 'Référence',
    offer_created: 'Création',
    offer_type: 'Type',
    offer_name: 'Nom',
    offer_updated_by_name: 'Dernière modification par',

    offer_add_relation: 'Relation',
    offer_add_characteristic: 'Caractéristiques',
    offer_add_characteristic: 'Caractéristiques',
    offer_add_risks: 'Risques à assurer',
    offer_add_recipients: 'Destinataires',

    offer_vehicule_brand: 'Marque',
    offer_vehicule_type: 'Type',
    offer_vehicule_registration_number: 'Matricule',
    offer_vehicule_frame: 'Chassis',
    offer_vehicule_release: 'Mise en circulation',
    offer_vehicule_displacement: 'Cylindrée',
    offer_vehicule_approval: 'Homologation',
    offer_vehicule_price: 'Prix',
    offer_vehicule_accessories: 'Accessoires',

    offer_vehicule_weight: 'Poids',
    offer_vehicule_garage: 'Garage',
    offer_vehicule_usage: 'Utilisation',
    offer_vehicule_km_per_year: 'km/an',
    offer_vehicule_km_odometer: 'km compteur',
    offer_vehicule_leasing: 'Leasing',
    offer_vehicule_plates: 'Plaques',
    offer_vehicule_current_insurer: 'Assureur Actuel',
    offer_vehicule_personal_effects_value: 'Valeur effets personnels',

    offer_cc: 'CC',
    offer_reply_to: 'Répondre à',
    offer_test: 'Remplacer les destinataires par',


    offer_clone: "Faire une copie",
    offer_send_all: 'Envoyer les demandes',

    document_name: 'Nom',
    document_created: 'Date',

    documents_title: 'Documents',
    documents_subtitle: 'Documents du dossier',
    documents_parents: 'Parent',
    documents_back_to: 'Retour au dossier parent',
    documents_folders: 'Sous dossiers',
    documents_documents: 'Documents',
    documents_upload: 'téléverser',
    documents_download: 'télécharger',
    documents_email: 'email',
    documents_rename: 'rename',
    documents_remove: 'supprimer',
    documents_remove_confirm: 'Supprimer définitivement {name} ?',
    documents_rename_confirm: 'Renommer le document en :',

    documents_selected_documents:
      '{count} document sélectioné | {count} documents sélectionés',
    documents_selected_email:
      'Envoyer le document sélectionné par email | Envoyer les {count} documents sélectionnés par email',
    documents_selected_remove:
      'Supprimer le document sélectionné | Supprimer les {count} documents sélectionnés',
    documents_selected_remove_confirm:
      'Supprimer le document sélectionné ?| Supprimer les {count} documents sélectionnés ?',
    policies_display_cancelled: 'afficher les polices archivées',

    relation_policy_title: '{insurerName} {branchName} {number}',
    relation_policy_subtitle: 'pour {name} ({status})',

    policy_add: 'Ajouter une police',
    policy_updated: 'Mise à jour',
    policy_insurer: 'Assureur',
    policy_branch: 'Branche',
    policy_number: 'Référence',
    policy_date_start: 'Début',
    policy_date_until: 'Échéance',
    policy_premium: 'Prime',
    policy_note: 'Notes',

    insurers_title: 'Assureurs',
    insurers_subtitle: "Tous les compangnies d'assurance",
    insurer_id: 'Ref',
    insurer_company: 'Name',
    insurer_telephone: 'Tél.',
    insurer_email: 'email',
    insurer_bpv: 'Code BPV',
    insurer_links: 'Liens',
    insurer_policies: 'les polices',

    branches_title: 'Branches',
    branches_subtitle: '',
    branch_name: 'Nom de la branche',
    branch_abrev: 'Abréviation',
    branch_links: 'Liens',
    branch_policies: 'les polices',

    search_relations: 'Relations',
    search_policies: 'Polices',
    search_index: 'Indexer',
    search_index_relations: 'Indexer les relations',
    search_index_policies: 'Indexer les polices',
    search_query_placeholder: 'Rechercher…',
    search_archived_include: 'aussi dans les archives',

    emails_date: 'Date',
    emails_to: 'À',
    emails_template: 'Modèle',
    emails_subject: 'Sujet',
    emails_body_text: 'Corps (texte)',
    emails_body_html: 'Corps (html)',
    emails_body_amp: 'Corps (amp)',
    emails_delivery_state: 'Statut',

    emails_welcome: 'Email de bienvenue',

    emails_templates_add: "Ajouter un nouveau modèle d'email",
    emails_template_id: "Identifiant",
    emails_template_name: "Nom",
    emails_template_help: "ce champs n'est pas montré aux relations",
    emails_template_type: "Type",
    emails_template_subject: "Sujet",

    email_template: 'Modèle',
    email_to: 'Destinataire (to)',
    email_cc: 'Copie-Carbone (cc)',
    email_subject: 'Object (subject)',
    email_text: 'Message (texte)',
    email_attachments: 'Pièces jointes',
    email_send: 'Envoyer',

    breadcrumb_broker: '{name}',
    breadcrumb_relations: 'relations',
    breadcrumb_relations_add: 'ajouter une relation',
    breadcrumb_relation: '{name}',
    breadcrumb_relation_name: 'informations générales (MàJ)',
    breadcrumb_relation_management: 'gestion (MàJ)',
    breadcrumb_relation_address: 'adresse',
    breadcrumb_relation_coordinates: 'coordonnées',
    breadcrumb_relation_bank_account: 'coordonnées de paiement',
    breadcrumb_relation_notes: 'notes',
    breadcrumb_relation_prospection: 'prospection',
    breadcrumb_relation_driver: 'conducteur',
    breadcrumb_relation_offers: 'offres',
    breadcrumb_relation_offers_add_vehicule: "nouvel appel d'offres (vehicule)",
    breadcrumb_relation_offers_vehicule: "appel d'offres ({reference})",
    breadcrumb_relation_policies: 'polices',
    breadcrumb_relation_policies_add: 'nouvelle police',
    breadcrumb_relation_documents: 'documents',
    breadcrumb_relation_emails: 'emails',
    breadcrumb_relation_email: 'nouvel email',
    breadcrumb_relation_policy: 'police {number}',
    breadcrumb_search_relations: "Recherche",
    breadcrumb_search_policies: "Recherche",
    breadcrumb_search_index: "Indexer",
    breadcrumb_policies: "Polices",
    breadcrumb_settings: "Paramètres",
    breadcrumb_settings_email: "Paramètres emails",
    breadcrumb_emails_templates: "Modèles d'emails",
    breadcrumb_emails_template: 'Modèle {id}',

    buttons_cancel: 'Annuler',
    buttons_update: 'Modifier',
    buttons_archive: 'Archiver',
    buttons_unarchive: 'Désarchiver',
    buttons_save: 'Sauvegarder',

    uploader_files: 'Fichiers',
    uploader_upload: 'Téléversement',
    uploader_dnd_available: 'Vous pouvez aussi glisser déposer',
    uploader_dnd_drop_here: 'Glisser les fichiers ici',
    footer_from: 'du',
    footer_by: 'par',
    footer_tos: 'tous droits réservés',

    update_banner_title: 'Nouvelle version',
    update_banner_updateFound:
      'Une nouvelle version de BRKR est en cours de téléchargement',
    update_banner_updated: 'La nouvelle version de BRKR est téléchargée',
    update_banner_action: 'Mettre à jour',

    // pagination
    pagination_previous: 'Précédente',
    pagination_next: 'Page suivante',
    pagination_goto: 'Aller à la page {page}',
  }
};
